@import '../../styles/helpers/index.scss';

.coupon-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100vh;
  background-color: rgba(#000, 0.5);

  z-index: 99999;

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #fff;

    max-width: 500px;
    width: 100%;
    height: 350px;

    border: 2px solid map-get($colors, 'red');

    @include align-center;
    justify-content: center;
  }

  .close-btn {
    height: 20px;
    width: 20px;
    border-radius: 5px;
    position: absolute;

    top: 15px;
    right: 30px;

    cursor: pointer;

    &::after {
      content: '\274c';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 30px;
      text-align: center;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    input {
      cursor: pointer;

      &:not([type='submit']) {
        padding: 0 15px;
        height: 50px;
        line-height: 50px;
        width: 300px;

        border-radius: 20px;
        border: 1px solid map-get($colors, 'text');

        font-size: 16px;

        &::placeholder {
          font-family: inherit;
        }
      }
    }
  }
}
