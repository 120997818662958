@import '../../../styles/helpers/index.scss';

.benefits {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &__list {
    margin: 45px 0 0;

    @media screen and (max-width: $tablet-sm) {
      margin: 75px 0 0;
    }

    &--item {
      display: flex;
      margin-bottom: 1px;

      .content {
        padding: 60px 40px;
        max-width: 70%;
        width: 100%;
        background-color: #fff;

        border: 1px solid map-get($colors, 'red');
        border-radius: 20px;

        @media screen and (max-width: $tablet-sm) {
          max-width: 100%;
        }

        h4 {
          font-family: 'Fivo Sans Modern Bold', sans-serif;
          font-size: 40px;
          line-height: 120%;
          color: map-get($colors, 'red');
        }

        p {
          margin: 40px 0 0;
          font-size: 18px;
          line-height: 150%;
        }
      }

      .image {
        border-radius: 20px;
        overflow: hidden;

        @media screen and (max-width: $tablet-sm) {
          display: none;
        }
      }
    }
  }
}
