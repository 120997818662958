@import '../../styles/helpers/index.scss';

.blog {
  margin: 120px 0 0;
  padding: 0 0 135px;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &__posts {
    margin: 60px 0 0;
    @include grid($row-gap: 60px, $column-gap: 20px);
  }

  .categories {
    @include align-center;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }

    &--item {
      padding: 15px 30px;

      border: 2px solid map-get($colors, 'red');
      border-radius: 20px;
      color: map-get($colors, 'red');

      font-weight: 600;
      font-size: 16px;
      line-height: 120%;

      text-align: center;

      cursor: pointer;

      transition: all 0.3s ease;

      &:hover {
        background-color: map-get($colors, 'red');
        color: #fff;
      }

      &.active {
        background-color: map-get($colors, 'red');
        color: #fff;
      }
    }
  }
}

.post-card {
  &__image {
    border-radius: 20px;
    height: 400px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  &__info {
    background-color: #fff;
    border: 1px solid map-get($colors, 'red');
    border-radius: 20px;
    padding: 40px;

    color: map-get($colors, 'text');

    & > span {
      font-weight: 300;
      font-size: 16px;
      line-height: 155%;
    }

    h3 {
      margin: 30px 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
    }
  }
}
