@import '../../../styles/helpers/index.scss';

.offers {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  .container {
    background-image: url('../../../static/offers-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    border-radius: 20px;

    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      border-radius: 0;
    }
  }

  &__inner {
    padding: 100px 40px;

    display: flex;
    gap: 45px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      padding: 70px 0;
    }

    & > div {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
        width: auto;
      }
    }

    .content {
      p {
        margin: 25px 0 0;
        font-size: 18px;
        line-height: 150%;
      }

      .btn {
        margin: 65px 0 0;
        display: block;
        max-width: 170px;
        text-align: center;

        @media screen and (max-width: $tablet-sm) {
          margin: 30px 0 0;
        }
      }

      img {
        margin: 15px 0 0;
        user-select: none;
        float: right;

        @media screen and (max-width: $tablet-sm) {
          display: none !important;
        }
      }
    }

    .coupons {
      display: flex;
      flex-direction: column;
      gap: 40px;

      & > div {
        @include scale;
        cursor: pointer;
      }
    }
  }

  .section-title {
    max-width: 275px;
  }
}
