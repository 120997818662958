@import '../../styles/helpers/index.scss';

.contacts {
  margin: 120px 0 0;
  position: relative;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &::before {
    content: '';
    background-image: url('../../static/pseudo-decor/services.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    width: 1110px;
    height: 830px;

    position: absolute;
    right: 50%;
    transform: translateY(-50%);
    z-index: -1;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  &__inner {
    display: flex;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
        width: auto;
      }
    }
  }

  &__form {
    padding: 30px 40px 50px;
    background-color: map-get($colors, 'red');
    border-radius: 20px;

    @media screen and (max-width: $tablet-sm) {
      padding: 30px 20px;
    }

    h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 116%;
      color: #fff;

      @media screen and (max-width: $tablet-sm) {
        text-align: center;
      }
    }

    form {
      margin: 40px 0 0;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0 0;
      }

      input,
      textarea,
      select {
        font-family: 'Inter', sans-serif;
        border: none;
        border-radius: 20px;
        min-height: 50px;
        padding: 0 20px;
        font-size: 16px;
        outline: none;

        &::placeholder {
          font-family: inherit;
        }
      }

      textarea {
        resize: none;
        height: 120px;
        padding-top: 20px;
      }

      input[type='submit'] {
        max-width: 170px;
        cursor: pointer;
      }
    }
  }

  &__info {
    padding: 60px 50px;
    border: 1px solid map-get($colors, 'red');
    border-radius: 20px;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: $tablet-sm) {
      padding: 30px 20px;
      gap: 15px;
    }

    p,
    a {
      position: relative;
      display: block;
      font-size: 26px;
      line-height: 150%;
      color: #303030;
      text-indent: 45px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 15px;
        text-indent: 30px;
      }

      &::before {
        content: '';

        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        width: 30px;
        height: 30px;

        position: absolute;
        top: 50%;
        left: 0;

        transform: translateY(-50%);

        @media screen and (max-width: $tablet-sm) {
          width: 20px;
          height: 20px;
        }
      }
    }

    a:hover {
      text-decoration: underline !important;
    }

    .address::before {
      background-image: url('../../static/address.svg');
    }

    .phone::before {
      background-image: url('../../static/phone.svg');
    }

    .email::before {
      background-image: url('../../static/email.svg');
    }

    .hours::before {
      background-image: url('../../static/clock.svg');
    }
  }

  &__map {
    margin: 120px 0 0;

    @media screen and (max-width: $tablet-sm) {
      margin: 60px 0 0;
    }

    iframe {
      width: 100%;
      min-height: 800px;
      vertical-align: middle;

      @media screen and (max-width: $tablet-sm) {
        min-height: 300px;
      }
    }
  }
}
