@import '../../../styles/helpers/index.scss';

.contact-our-specialists {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  .container {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      border-radius: 20px;
      height: 100%;
      background-color: #fff;
      z-index: -1;

      @media screen and (max-width: $tablet-sm) {
        border-radius: 0;
      }
    }

    &::before {
      width: 65%;
      left: 0;
      background-color: map-get($colors, 'text');
    }

    &::after {
      width: 35%;
      right: 0;
      background-color: map-get($colors, 'red');
    }
  }

  &__inner {
    padding: 80px 40px;
    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      padding: 60px 0;
    }

    & > p {
      margin: 15px 0 0;
      font-size: 18px;
      line-height: 150%;
      max-width: 670px;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    form {
      margin: 40px 0 0;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0 0;
        flex-direction: column;
      }

      & > div {
        max-width: 49%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 25px;

        &:last-of-type {
          max-width: 100%;
        }

        @media screen and (max-width: $tablet-sm) {
          max-width: 100%;
        }
      }

      input,
      textarea,
      select {
        font-family: 'Inter', sans-serif;
        border: none;
        border-radius: 20px;
        min-height: 50px;
        padding: 0 20px;
        font-size: 16px;
        outline: none;

        &::placeholder {
          font-family: inherit;
        }
      }

      textarea {
        resize: none;
        height: 125px;
        padding-top: 20px;
      }

      input[type='submit'] {
        display: block;
        max-width: 170px;
        width: 100%;
        cursor: pointer;
        background-color: map-get($colors, 'red');
      }
    }
  }
}
