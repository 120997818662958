@import '../../styles/helpers/index.scss';

.additional-menu {
  position: fixed;
  right: 0;
  top: 30%;
  z-index: 9999;

  width: 220px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: $tablet-sm) {
    display: none;
  }

  a {
    width: 35%;
    right: 0;
    position: absolute;
    @include align-center;
    gap: 15px;
    color: #fff;
    padding: 15px;
    border-radius: 20px 0 0 20px;
    background-color: map-get($colors, 'red');

    transition: all 0.3s ease;

    &:first-of-type {
      top: -80px;
    }

    &:nth-of-type(2n) {
      top: 0;
      background-color: map-get($colors, 'darkblue');
    }

    &:nth-of-type(3) {
      top: 80px;
    }

    &:last-of-type {
      top: 160px;
    }

    &:hover {
      width: 100%;
      span {
        display: block;
        width: 135px;
      }
    }

    img {
      width: 40px !important;
      height: 40px !important;
      object-fit: contain;
    }

    span {
      display: none;
      width: 0;
      white-space: nowrap;
      transition: all 0.3s ease;
    }
  }
}
