@import '../../styles/helpers/index.scss';

.booking-form {
  padding: 60px 0;
  max-width: 600px;
  margin: 0 auto;

  h1 {
    font-family: 'Fivo Sans Modern Bold', sans-serif;
    font-size: 62px;
    font-weight: bold;
    line-height: 120%;
    text-align: center;
  }

  form {
    margin: 40px 0 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
    }

    input,
    textarea,
    select {
      font-family: 'Inter', sans-serif;
      border: none;
      border-radius: 20px;
      min-height: 50px;
      padding: 0 20px;
      font-size: 16px;
      outline: none;

      &::placeholder {
        font-family: inherit;
      }
    }

    textarea {
      resize: none;
      height: 120px;
      padding-top: 20px;
    }

    input[type='submit'] {
      display: block;
      max-width: 170px;
      width: 100%;
      cursor: pointer;
      margin: 0 auto;
    }
  }

  .data-protection-label {
    margin: 5px auto 0;
  }
}
