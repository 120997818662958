@import '../../../styles/helpers/index.scss';

.areas {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &__inner {
    padding: 100px 40px 50px;
    display: flex;

    background-image: url('../../../static/areas-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    border-radius: 20px;

    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      padding: 60px 20px;
    }

    & > * {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
      }

      .decor2 {
        margin: 100px 0 0;
      }

      .decor3 {
        margin: 30px 60px 0 0;
        float: right;
      }
    }

    img {
      user-select: none;

      @media screen and (max-width: $tablet-sm) {
        display: none !important;
      }
    }

    ul {
      columns: 2;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0 0;
      }

      li {
        position: relative;
        text-indent: 20px;
        font-size: 18px;
        line-height: 180%;

        @media screen and (max-width: $tablet-sm) {
          font-size: 14px;
          white-space: nowrap;
        }

        &:not(:last-of-type) {
          // margin: 0 0 20px;
        }

        &::before {
          content: '';

          background-image: url('../../../static/list-arrow.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;

          width: 10px;
          height: 8px;

          position: absolute;
          left: 0;
          top: 50%;

          transform: translateY(-50%);
        }
      }
    }
  }
}
