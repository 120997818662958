@import '../../styles/helpers/index.scss';

.page-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: box-shadow 0.3s ease;

  background-color: map-get($colors, 'background');

  padding: 30px 0;

  &.shadow {
    box-shadow: 0 5px 15px 0 rgba(#000, 0.5);
  }

  .container {
    @include align-center;
    justify-content: space-between;
  }

  .mobile-phone {
    display: none;

    @media screen and (max-width: $tablet-sm) {
      display: block;
    }
  }

  .header-logo {
    height: 45px;
    width: auto;
    display: block;

    @media screen and (max-width: $tablet-sm) {
      height: 20px;
    }

    img {
      height: inherit;
      width: auto;
      object-fit: cover;
    }
  }

  nav {
    position: relative;
    @include align-center;
    gap: 30px;
    transition: left 0.3s ease;

    &.open {
      left: 0;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 15px;

      @media screen and (min-width: $tablet-sm) {
        display: none;
      }
    }

    @media screen and (max-width: $tablet-sm) {
      position: absolute;
      top: -30px;
      left: -150%;

      width: 100vw;
      height: 100vh;

      flex-direction: column;
      background-color: #fff;

      justify-content: center;
    }
  }

  .nav-menu {
    @include align-center;
    gap: 25px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 10px;
    }

    .active {
      color: map-get($colors, 'red') !important;
    }

    li {
      @media screen and (max-width: $tablet-sm) {
      }
    }

    a,
    span {
      color: map-get($colors, 'text');
      font-weight: 600;
      font-size: 14px;
      line-height: 110%;
      cursor: pointer;
      text-transform: uppercase;

      @media screen and (max-width: $tablet-sm) {
        text-transform: none;
        font-size: 22px;
        font-weight: normal;
      }

      &:hover {
        color: map-get($colors, 'red') !important;
      }
    }

    span {
      position: relative;
      display: block;

      &::before {
        content: '';
        background-image: url('../../static/nav-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        width: 10px;
        height: 6px;

        position: absolute;
        top: 50%;
        right: -15px;
        transform: translateY(-50%) rotate(180deg);

        @media screen and (max-width: $tablet-sm) {
          display: none;
        }
      }

      &:hover {
        &::before {
          background-image: url('../../static/nav-arrow_hover.svg');
          transform: translateY(-50%) rotate(0);

          @media screen and (max-width: $tablet-sm) {
            display: none;
          }
        }
      }

      @media screen and (max-width: $tablet-sm) {
        display: block;
        text-align: center;
        margin: 0 0 15px;
      }
    }

    .nav-submenu {
      position: relative;
      padding: 15px 0;

      span {
        &.active {
          &::before {
            background-image: url('../../static/nav-arrow_hover.svg');
          }
        }
      }

      &:hover {
        span {
          color: map-get($colors, 'green') !important;
        }

        ul {
          display: block;
        }
      }

      @media screen and (max-width: $tablet-sm) {
        padding: 0;
      }

      ul {
        display: none;
        position: absolute;
        left: 0;
        top: 45px;

        background-color: #fff;
        border-radius: 20px;

        padding: 20px 30px;

        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

        @media screen and (max-width: $tablet-sm) {
          display: block;

          position: relative;
          border: none;
          box-shadow: none;
          top: 0;
          padding: 0;
          min-width: unset;
          text-align: center;
        }

        li {
          @media screen and (max-width: $tablet-sm) {
            &:not(:last-of-type) {
              margin: 0 0 15px;
            }
          }
        }

        a {
          color: map-get($colors, 'text');
          font-size: 14px;
          line-height: 220%;
          white-space: nowrap;
          font-weight: 400;

          @media screen and (max-width: $tablet-sm) {
            display: block;
            font-size: 18px;
            line-height: 100%;
          }
        }
      }
    }
  }

  .header-buttons {
    @include align-center;
    gap: 15px;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }

    .btn {
      min-width: unset !important;
    }

    .btn.red {
      position: relative;
      text-indent: 22px;

      &::before {
        content: '';
        background-image: url('../../static/phone_white.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        width: 18px;
        height: 18px;

        position: absolute;
        left: 12px;
        transition: all 0.3s ease;
      }

      &:hover {
        &::before {
          background-image: url('../../static/phone_red.svg');
        }
      }
    }
  }
}

.burger {
  display: none;
  width: 30px;

  span {
    display: block;
    border-bottom: 2px solid map-get($colors, 'heading');
    &:nth-of-type(2) {
      margin: 8px 0;
    }
  }

  @media screen and (max-width: $tablet-sm) {
    display: block;
  }
}

.close {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 40px;
  height: 40px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: calc((40px - 1px) / 2);
    left: 0;
    right: 0;
    height: 1px;
    background: map-get($colors, 'darkblue');
    border-radius: 1px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
