@import '../../styles/helpers/index.scss';

.call-us {
  margin: 120px 0 0;
  position: relative;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &::before {
    content: '';
    background-image: url('../../static/pseudo-decor/cta.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    width: 1110px;
    height: 830px;

    position: absolute;
    left: 0;
    z-index: -1;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
  }

  &__content {
    position: relative;
    max-width: 70%;
    background-color: map-get($colors, 'red');
    width: 100%;
    border-radius: 20px;

    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;

    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
      padding: 30px;
      text-align: center;
    }

    &::before {
      content: '';

      background-image: url('../../static/call-us.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      width: 60px;
      height: 60px;

      position: absolute;
      bottom: 50px;
      right: -30px;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    .links {
      margin: 40px 0 0;

      @include align-center;
      gap: 30px;

      @media screen and (max-width: $tablet-sm) {
        flex-direction: column;
        gap: 15px;
      }

      a {
        max-width: 170px;
        width: 100%;
        text-align: center;
      }

      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 200%;

        a {
          color: #fff;

          &:hover {
            text-decoration: underline !important;
          }
        }

        @media screen and (max-width: $tablet-sm) {
          font-size: 18px;
        }
      }
    }
  }

  &__image {
    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }
}

#services .call-us {
  margin-bottom: 120px;

  @media screen and (max-width: $tablet-sm) {
    margin-bottom: 60px;
  }
}
