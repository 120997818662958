@import '../../../styles/helpers/index.scss';

.services-description {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;

    &:nth-of-type(2n) {
      flex-direction: row-reverse;

      @media screen and (max-width: $tablet-sm) {
        flex-direction: row;
      }
    }

    &:not(:last-of-type) {
      margin: 0 0 120px;

      @media screen and (max-width: $tablet-sm) {
        margin: 0 0 60px;
      }
    }

    &--content {
      padding: 80px 100px 85px 40px;
      border: 1px solid map-get($colors, 'red');
      border-radius: 20px;
      background-color: #fff;

      @media screen and (max-width: $tablet-sm) {
        padding: 60px 20px;
      }

      h3 {
        font-family: 'Fivo Sans Modern Bold', sans-serif;
        font-size: 46px;
        line-height: 120%;
        font-weight: 700;

        @media screen and (max-width: $tablet-sm) {
          font-size: 32px;
        }
      }

      .mobile-image {
        margin: 15px 0;
        display: none !important;
        height: 300px;
        width: 100%;
        object-fit: cover;

        @media screen and (max-width: $tablet-sm) {
          display: block !important;
        }
      }

      & > div {
        margin: 30px 0 0;

        p {
          font-size: 18px;
          line-height: 150%;

          &:not(:last-of-type) {
            margin: 0 0 20px;
          }
        }

        ul {
          list-style-type: disc;
          font-size: 18px;
          line-height: 150%;
          margin: 0 0 20px;
          padding: 0 0 0 20px;
        }
      }

      .btn {
        margin: 30px 0 0;
        display: block;
        max-width: 170px;
        text-align: center;
      }
    }

    &--image {
      max-width: 30%;
      width: 100%;

      border-radius: 20px;
      overflow: hidden;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
