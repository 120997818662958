@import '../../../styles/helpers/index.scss';

.why-choose-us {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  .counter {
    margin: 30px 0 0;
    position: relative;

    @include grid($columns: repeat(3, 1fr), $column-gap: 0);

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      border-radius: 20px;

      height: 100%;
      background-color: map-get($colors, 'darkblue');
      z-index: -1;

      @media screen and (max-width: $tablet-sm) {
        border-radius: 0;
      }
    }

    &::before {
      width: 65%;
      left: 0;
    }

    &::after {
      width: 35%;
      right: 0;
    }

    &__item {
      padding: 70px 0 180px;
      text-align: center;
      color: #fff;

      @media screen and (max-width: $tablet-sm) {
        padding: 70px 0 0;
      }

      p {
        &:first-of-type {
          font-family: 'Fivo Sans Modern Bold', sans-serif;
          font-size: 80px;
          line-height: 150%;
        }
        &:last-of-type {
          font-weight: 700;
          font-size: 24px;
          line-height: 150%;
        }
      }
    }
  }

  .reasons {
    margin: -120px 0 0;
    padding: 0 40px;

    @include grid($columns: repeat(3, 1fr), $column-gap: 20px);

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
      padding: 0;
    }

    &__item {
      padding: 40px 40px 60px;

      background-color: #fff;
      border: 1px solid map-get($colors, 'red');
      border-radius: 20px;

      @media screen and (max-width: $tablet-sm) {
        padding: 30px 20px;
      }

      &--icon {
        position: relative;
        width: 90px;
        height: 90px;
        background-color: map-get($colors, 'red');
        border-radius: 50%;

        @include align-center;
        justify-content: center;

        @media screen and (max-width: $tablet-sm) {
          width: 80px;
          height: 80px;
        }

        &::before {
          content: '';

          width: 95px;
          height: 95px;

          border-radius: 50%;
          border: 1px solid map-get($colors, 'red');

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @media screen and (max-width: $tablet-sm) {
            width: 85px;
            height: 85px;
          }
        }
      }

      h4 {
        margin: 30px 0 0;
        font-family: 'Fivo Sans Modern Bold', sans-serif;
        font-size: 24px;
        line-height: 120%;
      }

      p {
        margin: 20px 0 0;
        font-size: 18px;
        line-height: 150%;
      }
    }
  }
}
