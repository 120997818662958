@import '../../../styles/helpers/index.scss';

.home-about {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  // &::before {
  //   content: '';

  //   background-image: url('../../../static/home-about-label.svg');
  //   background-size: contain;
  //   background-repeat: no-repeat;

  //   width: 220px;
  //   height: 100px;

  //   position: absolute;
  //   left: 50%;
  //   bottom: -45px;
  //   transform: translateX(-50%);

  //   z-index: 1;
  //   user-select: none;

  //   @media screen and (max-width: $tablet-sm) {
  //     bottom: -50px;
  //   }
  // }

  &__inner {
    display: flex;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 30%;
      width: 100%;
      border-radius: 20px;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
        width: auto;
      }

      &:first-of-type {
        max-width: 70%;
        position: relative;
        padding: 40px;
        background-color: #fff;
        border: 1px solid map-get($colors, 'red');

        @media screen and (max-width: $tablet-sm) {
          padding: 30px 20px 60px;
          order: 2;
          max-width: unset;
        }

        &::before {
          content: '';

          background-image: url('../../../static/quality.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;

          height: 124px;
          width: 124px;

          position: absolute;
          z-index: 99;

          right: -90px;
          bottom: 25%;

          user-select: none;

          @media screen and (max-width: $tablet-sm) {
            top: 15px;
            right: 15px;

            width: 90px;
            height: 90px;
          }
        }

        & > div {
          margin: 40px 0 0;

          p {
            font-size: 18px;
            line-height: 150%;

            &:not(:last-of-type) {
              margin: 0 0 20px;
            }
          }

          ul {
            font-size: 18px;
            line-height: 150%;
            list-style-type: disc;
            padding-left: 30px;
            margin: 0 0 30px;
          }
        }
      }

      &:last-of-type {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        min-height: 640px;

        @media screen and (max-width: $tablet-sm) {
          order: 1;
          min-height: auto;
          height: 300px;
        }
      }
    }

    .section-title {
      position: relative;
      color: map-get($colors, 'red');

      &::before {
        content: '';

        background-image: url('../../../static/home-about-title.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        width: 300px;
        height: 38px;

        position: absolute;
        bottom: -35px;
        left: 15%;

        @media screen and (max-width: $tablet-sm) {
          display: none;
        }
      }
    }
  }
}
