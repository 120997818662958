@import '../../../styles/helpers/index.scss';

.brands {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  .container {
    display: flex;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
  }

  &__title {
    padding: 50px 40px;
    border: 1px solid map-get($colors, 'red');
    border-radius: 20px;
    background-color: #fff;

    @media screen and (max-width: $tablet-sm) {
      padding: 0;
      border: 0;
      background-color: transparent;
    }

    img {
      margin: 70px 0 0;

      @media screen and (max-width: $tablet-sm) {
        display: none !important;
      }
    }
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
    }
  }

  &__item {
    max-width: calc(25% - 20px);
    width: 100%;
    height: 140px;

    border: 1px solid map-get($colors, 'red');
    border-radius: 20px;
    background-color: #fff;

    @include align-center;
    justify-content: center;

    @media screen and (max-width: $tablet-sm) {
      max-width: calc(50% - 20px);
      height: 100px;

      img {
        width: 50%;
        object-fit: contain;
      }
    }
  }
}
