@import '../../../styles/helpers/index.scss';

.articles {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &__list {
    margin: 45px 0 0;

    @media screen and (max-width: $tablet-sm) {
      margin: 75px 0 0;
    }

    .article-card {
      display: block;
      max-width: 400px;

      &__image {
        height: 400px;
        overflow: hidden;
        border-radius: 20px;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;

          @media screen and (max-width: $tablet-sm) {
            object-fit: contain;
          }
        }
      }

      &__info {
        background-color: #fff;
        border: 1px solid map-get($colors, 'red');
        border-radius: 20px;
        padding: 40px 35px;

        color: map-get($colors, 'text');

        span {
          font-size: 16px;
          line-height: 155%;
        }

        h3 {
          margin: 20px 0 0;
          font-weight: 700;
          font-size: 18px;
          line-height: 150%;
          height: 100px;
        }
      }
    }
  }
}
