@import '../../styles/helpers/index.scss';

.error404 {
  @include align-center;
  justify-content: center;
  width: 100%;
  text-align: center;

  h1 {
    font-family: 'Fivo Sans Modern Bold', sans-serif;
    font-size: 62px;
    font-weight: bold;
    line-height: 120%;
  }

  p {
    margin: 30px 0;
    font-size: 28px;
    line-height: 120%;
  }

  .btn {
    display: block;
    max-width: 170px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
}
