@import '../../styles/helpers/index.scss';

.common-hero {
  &__inner {
    position: relative;
    background-image: url('../../static/commercial-refrigeration.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;

    border-radius: 20px;
    color: #fff;

    padding: 165px 40px;

    @media screen and (max-width: $tablet-sm) {
      padding: 100px 20px;
    }

    h1 {
      font-family: 'Fivo Sans Modern Bold', sans-serif;
      font-size: 60px;
      line-height: 120%;

      @media screen and (max-width: $tablet-sm) {
        text-align: center;
        font-size: 38px;
      }
    }

    .block-footer {
      @include align-center;
      gap: 40px;
      position: absolute;
      right: 25px;
      bottom: 45px;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }

      .navigation {
        padding: 10px;
        @include grid($column-gap: 20px, $row-gap: 20px);
        background-color: #fff;
        border-radius: 20px;

        a,
        button {
          background-color: map-get($colors, 'text');
          color: #fff;
          cursor: pointer;

          &.red {
            background-color: map-get($colors, 'red');
          }
        }
      }

      img {
        // position: absolute;
        // bottom: 45px;
        // right: 25px;

        @media screen and (max-width: $tablet-sm) {
          display: none !important;
        }
      }
    }
  }
}
