@import '../../../styles/helpers/index.scss';

.home-services {
  position: relative;
  margin: 180px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &::before {
    content: '';
    background-image: url('../../../static/pseudo-decor/home-services.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    width: 1110px;
    height: 830px;

    position: absolute;
    right: 0;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  &__list {
    margin: 40px 0 0;
    @include grid($column-gap: 20px, $row-gap: 20px);
    // display: flex;
    // justify-content: space-between;
    // gap: 20px;

    // @media screen and (max-width: $tablet-sm) {
    //   flex-direction: column;
    // }
  }

  .service-card {
    padding: 40px 50px 60px;
    background-color: #fff;
    border: 1px solid map-get($colors, 'red');
    border-radius: 20px;

    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
      padding: 20px;
    }

    .icon {
      position: relative;
      width: 90px;
      height: 90px;
      background-color: map-get($colors, 'red');
      border-radius: 50%;

      @include align-center;
      justify-content: center;

      @media screen and (max-width: $tablet-sm) {
        width: 80px;
        height: 80px;
      }

      &::before {
        content: '';

        width: 95px;
        height: 95px;

        border-radius: 50%;
        border: 1px solid map-get($colors, 'red');

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: $tablet-sm) {
          width: 85px;
          height: 85px;
        }
      }

      img {
        object-fit: cover;
      }
    }

    .row {
      @include align-center;
      gap: 40px;
    }

    h4 {
      font-family: 'Fivo Sans Modern Bold', sans-serif;
      font-size: 30px;
      line-height: 120%;
    }

    p {
      margin: 30px 0 0;
      font-size: 18px;
      line-height: 150%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;
      }
    }

    .btn {
      margin: 40px 0 0;
      display: block;
      max-width: 160px;
      text-align: center;
    }
  }
}
