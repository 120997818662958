@import '../../styles/helpers/index.scss';

.container {
  position: relative;
  max-width: 1280px;
  width: 100%;
  height: inherit;
  margin: 0 auto;
  padding: 0;

  @media screen and (max-width: $desktop-sm) {
    width: auto;
    max-width: 100%;
    padding: 0 15px;
  }
}

.mobile-cta-buttons {
  display: none;

  position: fixed;
  z-index: 100;
  left: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;

  background: #fff;
  box-shadow: 0px 0px 10px 3px rgb(7 7 7 / 50%);

  transition: all 0.3s ease;

  a {
    @include align-center;
    justify-content: center;
    max-width: 50%;
    width: 100%;
    font-weight: bold;
    color: #fff;

    &:first-of-type {
      background-color: map-get($colors, 'red');
    }

    &:last-of-type {
      background-color: map-get($colors, 'text');
    }
  }

  &.off {
    bottom: -60px;
  }

  &.on {
    bottom: 0;
  }

  @media screen and (max-width: $tablet-sm) {
    display: flex;
  }
}
