@import '../../../styles/helpers/index.scss';

.faq {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &__inner {
    display: flex;
    gap: 100px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 30px;
    }
  }

  .section-title {
    max-width: 315px;

    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
    }
  }

  .accordion {
    &__item {
      padding: 45px 30px 40px;
      border: 1px solid map-get($colors, 'red');
      border-radius: 20px;
      background-color: #fff;

      cursor: pointer;

      @media screen and (max-width: $tablet-sm) {
        padding: 25px 15px 20px;
      }

      &:not(:last-of-type) {
        margin: 0 0 20px;
      }
    }

    &__heading {
      font-weight: 700;
      font-size: 24px;
      line-height: 155%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;
      }
    }

    &__panel {
      margin: 20px 0 0;
      font-size: 18px;
      line-height: 150%;
    }

    &__button {
      @include align-center;
      justify-content: space-between;

      .icon {
        position: relative;
        width: 60px;
        height: 60px;

        &::before {
          content: '';

          background-image: url('../../../static/accordion_close.svg');
          background-repeat: no-repeat;
          background-position: center;

          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          width: 60px;
          height: 60px;

          @media screen and (max-width: $tablet-sm) {
            width: 45px;
            height: 45px;
            background-size: cover;
          }
        }
      }

      &[aria-disabled='true'] {
        .icon {
          &::before {
            background-image: url('../../../static/accordion_open.svg');
          }
        }
      }
    }
  }
}
