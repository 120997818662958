@import '../../styles/helpers/index.scss';

.coupons-grid {
  margin: 120px 0 0;
  padding: 0 0 145px;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
    padding: 0 0 70px;
  }

  .categories {
    margin: 120px 0 0;
    @include align-center;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }

    &--item {
      padding: 15px 30px;

      border: 2px solid map-get($colors, 'red');
      border-radius: 20px;
      color: map-get($colors, 'red');

      font-weight: 600;
      font-size: 16px;
      line-height: 120%;

      text-align: center;

      cursor: pointer;

      transition: all 0.3s ease;

      &:hover {
        background-color: map-get($colors, 'red');
        color: #fff;
      }

      &.active {
        background-color: map-get($colors, 'red');
        color: #fff;
      }
    }
  }

  &__inner {
    margin: 80px 0 0;
    @include grid;

    @media screen and (max-width: $tablet-sm) {
      margin: 40px 0 0;
    }

    div {
      margin: 0 auto;
      border-radius: 20px;
      width: 100%;
      cursor: pointer;

      @include scale;

      img {
        object-fit: cover;
        width: 100%;
      }
    }
  }
}
