@import '../../styles/helpers/index.scss';

.cta {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  .container {
    background-color: map-get($colors, 'red');
    border-radius: 20px;
    color: #fff;
    text-align: center;

    @media screen and (max-width: $tablet-sm) {
      border-radius: 0;
    }
  }

  &__inner {
    padding: 70px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet-sm) {
      padding: 45px 0;
    }

    p {
      font-family: 'Fivo Sans Modern Bold', sans-serif;
      font-size: 40px;
      line-height: 200%;

      max-width: 935px;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        font-size: 24px;
      }

      span {
        display: inline-block;
        width: 115px;
        height: 115px;

        position: relative;

        @media screen and (max-width: $tablet-sm) {
          width: 60px;
          height: 60px;
        }

        &::before {
          content: '';
          background-image: url('../../static/cta.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;

          width: 115px;
          height: 115px;

          position: absolute;
          top: 100%;
          left: 50%;

          transform: translate(-50%, -70%);

          @media screen and (max-width: $tablet-sm) {
            width: 60px;
            height: 60px;
          }
        }
      }
    }

    .buttons {
      margin: 25px 0 0;
      display: flex;

      a {
        &:first-of-type {
          margin-right: 20px;
        }
      }

      .btn.blue-border {
        position: relative;
        text-indent: 22px;

        &::before {
          content: '';
          background-image: url('../../static/phone_blue.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;

          width: 18px;
          height: 18px;

          position: absolute;
          left: 12px;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
