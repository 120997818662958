@import '../../../styles/helpers/index.scss';

.home-hero {
  &__inner {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    border-radius: 20px;

    padding: 120px 40px 90px;

    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      padding: 160px 15px 60px;
    }

    .page-title {
      font-family: 'Fivo Sans Modern Bold', sans-serif;
      font-size: 72px;
      line-height: 110%;
      max-width: 600px;

      @media screen and (max-width: $desktop-md) {
        font-size: 60px;
      }

      @media screen and (max-width: $tablet-sm) {
        font-size: 34px;
        max-width: unset;
      }
    }

    .subtitle {
      margin: 15px 0 0;
      font-size: 30px;
      line-height: 120%;
    }

    .btn {
      max-width: 170px;
      display: block;
      margin: 60px 0 0;
    }
  }

  &__labels {
    @include align-center;

    position: absolute;
    right: 0;

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
      position: relative;
    }

    & > div {
      width: 300px;
      height: 100%;
      color: #fff;
      border-radius: 20px;
      padding: 12px 30px 30px;

      @media screen and (max-width: $tablet-sm) {
        text-align: center;
        padding: 10px 0px;

        width: 200px;
      }

      &:first-of-type {
        background-color: map-get($colors, 'darkblue');
      }

      &:last-of-type {
        background-color: map-get($colors, 'red');
      }

      p {
        &:first-of-type {
          font-family: 'Fivo Sans Modern Bold', sans-serif;
          font-size: 72px;
          line-height: 150%;

          @media screen and (max-width: $desktop-md) {
            font-size: 60px;
          }

          @media screen and (max-width: $tablet-sm) {
            font-size: 42px;
            line-height: 120%;
          }
        }

        &:last-of-type {
          font-size: 24px;
          line-height: 150%;

          @media screen and (max-width: $tablet-sm) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
