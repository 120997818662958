@import './helpers/variables.scss';
@import './helpers/mixins.scss';

html {
  height: 100% !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: map-get($colors, 'background');
  color: map-get($colors, 'text');
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Inter', sans-serif;

  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
    position: relative;
  }

  main {
    flex: 1 0 auto;
    padding: 145px 0 0;
    transition: all 0.3s ease;

    @media screen and (max-width: $desktop-md) {
      padding: 106px 0 0;
    }

    @media screen and (max-width: $tablet-sm) {
      padding: 109px 0 0;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  textarea {
    resize: none;
  }

  section {
    position: relative;
  }
}

.section-title {
  font-family: 'Fivo Sans Modern Bold', sans-serif;
  font-size: 60px;
  line-height: 120%;
  font-weight: bold;

  color: map-get($colors, 'heading');

  @media screen and (max-width: $tablet-sm) {
    font-size: 36px;
  }
}

.data-protection-label {
  @include align-center;

  svg {
    width: 20px;
    margin: 0 15px 0 0;
  }
}

.btn {
  min-width: 170px;
  text-align: center;
  padding: 11px 15px;

  text-transform: uppercase;
  color: #fff;

  font-weight: 600;
  font-size: 15px;
  line-height: 18px;

  border-radius: 50px;

  transition: all 0.3s ease;

  border: 1px solid transparent;

  @media screen and (max-width: $tablet-sm) {
    padding: 11px;
  }

  &.blue {
    background-color: map-get($colors, 'darkblue');

    &:hover {
      background-color: #fff;
      border-color: map-get($colors, 'darkblue');
      color: map-get($colors, 'darkblue');
    }
  }

  &.red {
    background-color: map-get($colors, 'red');

    &:hover {
      background-color: #fff;
      border-color: map-get($colors, 'red');
      color: map-get($colors, 'red');
    }
  }

  &.blue-border {
    border-color: map-get($colors, 'darkblue');
    color: map-get($colors, 'darkblue');

    &:hover {
      background-color: #fff;
    }
  }
}

b,
strong {
  font-weight: bold;
}
