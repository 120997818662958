@import '../../../styles/helpers/index.scss';

.about-service {
  margin: 120px 0 0;
  position: relative;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &::before {
    content: '';
    background-image: url('../../../static/pseudo-decor/services.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    width: 1110px;
    height: 830px;

    position: absolute;
    right: 0;
    z-index: -1;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  .section-title {
    max-width: 760px;

    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
    }
  }

  .content-grid {
    margin: 45px 0 0;
    @include grid;

    h2,
    h3,
    h4 {
      font-weight: bold;
      margin: 0 0 15px;
      font-size: 22px;
    }

    ul {
      list-style-type: disc;
      padding-left: 45px;
    }

    & > div {
      p {
        font-size: 18px;
        line-height: 150%;

        &:not(:last-of-type) {
          margin: 0 0 20px;
        }
      }
    }

    .btn {
      max-width: 300px;
      text-align: center;
    }
  }
}
