@import '../../../styles/helpers/index.scss';

.awards {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &__list {
    margin: 60px 0 0;

    @include align-center;
    justify-content: space-between;

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
      flex-direction: column;
      gap: 15px;
    }

    & > div {
      border-radius: 20px;
      border: 1px solid map-get($colors, 'red');
      width: 400px;
      height: 400px;
      background-color: #fff;
      @include align-center;
      justify-content: center;

      @media screen and (max-width: $tablet-sm) {
        width: 300px;
        height: 300px;
      }

      img {
        object-fit: cover;

        @media screen and (max-width: $tablet-sm) {
          width: 75%;
        }
      }
    }
  }
}
