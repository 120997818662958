@import '../../../styles/helpers/index.scss';

.home-about-services {
  position: relative;
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &::before {
    content: '';
    background-image: url('../../../static/pseudo-decor/home-about.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    width: 1110px;
    height: 830px;

    position: absolute;
    left: 0;
    z-index: -1;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  .subtitle {
    margin: 15px 0 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 150%;
    color: map-get($colors, 'red');

    @media screen and (max-width: $tablet-sm) {
      font-size: 18px;
    }
  }

  &__content {
    margin: 30px 0 0;
    @include grid($column-gap: 20px, $row-gap: 20px);

    h2 {
      font-weight: 700;
      font-size: 40px;
      line-height: 120%;
      margin: 0 0 15px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 28px;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 30px;
      line-height: 120%;
      margin: 0 0 15px;
      color: map-get($colors, 'red');

      @media screen and (max-width: $tablet-sm) {
        font-size: 22px;
      }
    }

    p {
      font-size: 18px;
      line-height: 150%;
      margin: 0 0 20px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;
      }
    }

    ul {
      list-style-type: disc;
      padding-left: 20px;
      margin: 15px 0;
    }
  }

  .services__list {
    margin: 45px 0 0;
    @include grid($column-gap: 20px, $row-gap: 20px);

    & > div {
      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
      }

      p {
        margin: 20px 0 0;
        font-size: 18px;
        line-height: 150%;
      }
    }
  }

  .notice {
    margin: 30px 0 0;
    padding: 50px 40px;
    background-color: map-get($colors, 'darkblue');
    color: #fff;
    border-radius: 20px;

    p {
      font-size: 18px;
      line-height: 150%;

      &:first-of-type {
        margin-top: 0 !important;
      }
    }
  }

  .give-a-call {
    margin: 30px 0 0;
    padding: 100px 0;
    background-image: url('../../../static/give-a-call.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    border-radius: 20px;

    @include align-center;
    flex-direction: column;
    justify-content: center;

    color: #fff;

    font-weight: 700;
    font-size: 24px;
    line-height: 180%;

    span {
      display: block;
    }

    a {
      color: #fff;
    }
  }
}
