@import '../../styles/helpers/index.scss';

.post {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 30px 0 0;
  }

  h1 {
    font-weight: 700;
    font-size: 54px;
    line-height: 120%;

    @media screen and (max-width: $tablet-sm) {
      font-size: 32px;
    }
  }

  &__inner {
    margin: 80px 0 0;
    display: flex;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      margin: 40px 0 0;
    }

    .content {
      max-width: 70%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
      }

      &__image {
        border-radius: 20px;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
        }
      }

      &__meta {
        margin: 60px 0 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 155%;
        color: map-get($colors, 'text');

        @media screen and (max-width: $tablet-sm) {
          margin: 30px 0 0;
        }
      }

      &__text {
        margin: 60px 0 0;

        @media screen and (max-width: $tablet-sm) {
          margin: 30px 0 0;
        }

        h2 {
          font-weight: 700;
          font-size: 40px;
          line-height: 120%;
          margin: 0 0 15px;

          @media screen and (max-width: $tablet-sm) {
            font-size: 28px;
          }
        }

        h3 {
          font-weight: 700;
          font-size: 30px;
          line-height: 120%;
          margin: 0 0 15px;
          color: map-get($colors, 'red');

          @media screen and (max-width: $tablet-sm) {
            font-size: 22px;
          }
        }

        p,
        ul,
        ol {
          margin: 0 0 15px;
          font-size: 18px;
          line-height: 150%;

          @media screen and (max-width: $tablet-sm) {
            font-size: 16px;
          }
        }

        ul {
          list-style-type: disc;
          padding-left: 30px;
        }

        ol {
          list-style-type: decimal;
          padding-left: 30px;
        }
      }
    }

    .sidebar {
      max-width: 30%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
      }

      h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 116%;
      }

      .post-form {
        padding: 45px 30px;
        background-color: #fff;
        border: 1px solid map-get($colors, 'red');
        border-radius: 20px;

        @media screen and (max-width: $tablet-sm) {
          padding: 45px 15px;
        }

        form {
          margin: 40px 0 0;
          display: flex;
          flex-direction: column;
          gap: 20px;

          @media screen and (max-width: $tablet-sm) {
            margin: 30px 0 0;
          }

          input,
          textarea,
          select {
            font-family: 'Inter', sans-serif;
            border-radius: 20px;
            min-height: 50px;
            padding: 0 20px;
            font-size: 16px;
            outline: none;
            border: 1px solid rgba(51, 51, 51, 0.4);

            &::placeholder {
              font-family: inherit;
            }
          }

          textarea {
            resize: none;
            height: 120px;
            padding-top: 20px;
          }

          input[type='submit'] {
            background-color: map-get($colors, 'red');
            color: #fff;
            border: none;
            display: block;
            max-width: 170px;
            width: 100%;
            cursor: pointer;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
