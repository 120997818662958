@import '../../styles/helpers/index.scss';

.page-footer {
  margin: 120px 0 0;
  padding: 80px 0 30px;
  background-color: map-get($colors, 'darkblue');
  color: #fff;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
    padding: 80px 0;
  }

  &__inner {
    display: flex;
    gap: 40px;
    width: 100%;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 30%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
      }
    }

    .logo {
      max-width: 200px;
      display: block;

      img {
        max-width: 100%;
      }
    }

    .footer-nav {
      display: flex;
      flex-direction: column;

      &__item {
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;

        &:not(:first-of-type) {
          margin: 40px 0 0;
        }
      }

      .submenu {
        margin: 15px 0 0;
      }

      &__subitem {
        color: #fff;
        font-size: 14px;
        line-height: 220%;

        &:not(:last-of-type) {
          margin: 0 0 15px;
        }
      }

      .hours {
        margin: 30px 0 0;

        p {
          &:first-of-type {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
          }

          &:last-of-type {
            margin: 10px 0 0;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;

    a,
    p {
      color: #fff;

      font-size: 16px;
      line-height: 155%;
    }

    .address {
      margin: 55px 0 0;
    }

    .email,
    .phone {
      margin: 20px 0 0;

      &:hover {
        text-decoration: underline;
      }
    }

    .social-media {
      margin: 40px 0 0;

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 155%;
        text-transform: uppercase;
      }

      &__icons {
        margin: 20px 0 0;
        @include align-center;
        gap: 15px;

        a {
          @include align-center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 50%;

          img {
            object-fit: contain;
          }
        }
      }
    }
  }

  .underline {
    @include align-center;
    justify-content: space-between;

    border-top: 1px solid #fff;
    padding: 16px 0 0;
    margin: 60px 0 0;

    font-weight: 400;
    font-size: 12px;
    line-height: 180%;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 15px;
    }

    a {
      color: #fff;

      &:hover {
        strong {
          text-decoration: underline;
        }
      }

      strong {
        font-weight: bold;
      }
    }
  }
}
