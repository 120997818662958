@import '../../../styles/helpers/index.scss';

.reviews {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  .section-title {
    max-width: 520px;
  }

  &__list {
    margin: 45px 0 0;

    @media screen and (max-width: $tablet-sm) {
      margin: 75px 0 0;
    }

    &--card {
      padding: 50px 40px;
      max-width: 400px;
      background-color: #fff;

      border: 1px solid map-get($colors, 'red');
      border-radius: 20px;

      header {
        @include align-center;
        gap: 30px;

        .logo {
          position: relative;

          background-color: map-get($colors, 'red');
          border-radius: 50%;

          width: 70px;
          height: 70px;

          @include align-center;
          justify-content: center;

          &::before {
            content: '';

            width: 75px;
            height: 75px;

            border: 1px dashed map-get($colors, 'red');
            border-radius: 50%;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .name {
          font-weight: 700;
          font-size: 18px;
          line-height: 150%;
        }

        .date {
          margin: 10px 0 0;
          font-size: 16px;
          line-height: 150%;
        }
      }

      & > p {
        font-size: 18px;
        line-height: 150%;
        height: 300px;
        overflow: scroll;
      }
    }

    &--card__stars {
      margin: 30px 0;
      @include align-center;
      gap: 10px;
    }
  }
}

.slick-slider {
  .slick-next,
  .slick-prev {
    width: 60px;
    height: 60px;
    background: #fff;

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: -100px;
    cursor: pointer;
    border: 1px solid map-get($colors, 'red');

    &::before {
      content: '';
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 30px;
      height: 20px;
    }
    @media screen and (max-width: 768px) {
      top: -40px;
      width: 45px;
      height: 45px;
    }
  }

  .slick-next {
    right: 20px;

    @media screen and (max-width: 768px) {
      right: 0;
    }

    &::before {
      content: '';
      background-image: url('../../../static/arrow-next.svg');
    }
  }
  .slick-prev {
    right: 100px;
    left: unset;

    &::before {
      content: '';
      background-image: url('../../../static/arrow-prev.svg');
    }

    @media screen and (max-width: 768px) {
      right: auto;
      left: 0;
    }
  }
}
