@import '../../../styles/helpers/index.scss';

.tools {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &__grid {
    margin: 60px 0 0;

    display: flex;
    gap: 20px;

    .image {
      max-width: 30%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }

      img {
        border-radius: 20px;
      }
    }

    .content {
      @include grid($column-gap: 20px, $row-gap: 20px);
      width: 100%;
    }
  }

  &__card {
    padding: 40px;
    border: 1px solid map-get($colors, 'red');
    border-radius: 20px;
    background-color: #fff;

    .icon {
      position: relative;
      width: 90px;
      height: 90px;
      background-color: map-get($colors, 'red');
      border-radius: 50%;

      @include align-center;
      justify-content: center;

      @media screen and (max-width: $tablet-sm) {
        width: 80px;
        height: 80px;
      }

      &::before {
        content: '';

        width: 95px;
        height: 95px;

        border-radius: 50%;
        border: 1px solid map-get($colors, 'red');

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: $tablet-sm) {
          width: 85px;
          height: 85px;
        }
      }
    }

    h4 {
      margin: 30px 0 0;
      font-family: 'Fivo Sans Modern Bold', sans-serif;
      font-size: 30px;
      line-height: 120%;
    }

    p {
      margin: 20px 0 0;
      font-size: 18px;
      line-height: 150%;
      height: 120px;
    }
  }
}
